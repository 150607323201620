<template>
  <div
    data-layout="pathfinder/pathfinder"
    class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display"
  >
    <div class="flex min-h-screen">
      <AppMenu />
      <div class="flex-grow mx-auto h-screen">
        <slot />
      </div>
    </div>
  </div>
</template>
